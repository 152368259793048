import styled from "styled-components"

import { useCallAssistActivateProvider } from "src/components/CallAssistActivate/CallAssistActivateContext"
import { TCallAssistActivateError } from "src/components/CallAssistActivate/callAssistTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type Props = {
  open: boolean
}

export function DebugSettings({ open }: Props) {
  const { callAssistErrorOverride, setCallAssistErrorOverride } =
    useCallAssistActivateProvider()

  if (!open) {
    return null
  }

  return (
    <GridBox>
      <SelectBox>
        <label htmlFor="set-ca-error">
          <MText variant="bodyS" color="secondary">
            Activation error
          </MText>
        </label>

        <select
          id="set-ca-error"
          onChange={(e) => {
            const err = e.target.value as TCallAssistActivateError
            setCallAssistErrorOverride(err ? err : undefined)
          }}
          value={callAssistErrorOverride}
        >
          <option value={""}></option>
          {options.map((o) => (
            <option key={o.error} value={o.error}>
              {o.label}
            </option>
          ))}
        </select>
      </SelectBox>
    </GridBox>
  )
}

const GridBox = styled.div`
  display: grid;
  gap: 1rem;
  padding-block: ${spacing.M};
`

const SelectBox = styled.div`
  display: grid;
`

const options: { label: string; error: TCallAssistActivateError }[] = [
  { label: "Wrong plan", error: "wrong plan" },
  { label: "Guard Assist Active", error: "GA active" },
  { label: "No homes", error: "no_homes" },
  { label: "Insufficient access", error: "insufficient permission" },
  { label: "Unknown error", error: "unknown error" },
  { label: "Already active error", error: "already_active" },
  // Potentially recoverable errors:
  { label: "Payment failed", error: "payment_error" },
  { label: "Generic activate error", error: "generic_error" },
]
