import styled from "styled-components"

import { TCallAssistEstimateResponse } from "src/data/callAssist/types/callAssistTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

import { getFormattedCallAssistCost } from "./getFormattedCallAssistCost"

export function CallAssistPriceTable({
  hidden,
  data,
}: {
  hidden?: boolean
  data: TCallAssistEstimateResponse | undefined
}) {
  const { t, langKeys } = useTranslate()

  if (hidden || !data) {
    return null
  }

  const renewalDate = data.renewal_date
  const nbrHomes = data.quantity
  const billingPeriod = getBillingPeriod(renewalDate)

  const { totalPriceMonthly, totalPriceYearly, priceBilledNow } =
    getFormattedCallAssistCost(data)

  const month = t(langKeys.month).toLocaleLowerCase()
  const year = t(langKeys.year).toLocaleLowerCase()
  const vatExempt = data.vat_exempt

  return (
    <TableContent>
      <TableContentRow>
        <TableTitle>
          <MText variant="subtitle">
            {t(langKeys.activate_call_assist_added_montly_cost, {
              0: nbrHomes,
            })}
          </MText>
        </TableTitle>

        <TableCellEndAlign>
          <MText variant="body">
            +&nbsp;
            {totalPriceMonthly}/{month}
          </MText>
          <MText variant="bodyS" color="secondary">
            {t(langKeys.billed_as)} {totalPriceYearly}/{year}
          </MText>
        </TableCellEndAlign>
      </TableContentRow>

      <GridDivider $margin={0} />

      <TableContentRow>
        <TableTitle>
          <MText variant="subtitle">{t(langKeys.billed_now)}</MText>
          <MText variant="bodyS" color="secondary">
            {billingPeriod}
          </MText>
        </TableTitle>

        <TableCellEndAlign>
          <MText variant="subtitle">{priceBilledNow}</MText>
          <MText variant="bodyS" color="secondary" hidden={vatExempt}>
            {t(langKeys.total_includes_vat)}
          </MText>
        </TableCellEndAlign>
      </TableContentRow>
    </TableContent>
  )
}

const TableContent = styled.div`
  background-color: ${mColors.accentPrimaryLight};
  border-radius: ${spacing.M};
  padding: ${spacing.M};
  gap: ${spacing.M};
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
`

const TableContentRow = styled.div`
  display: contents;
`

const TableCell = styled.div``

const TableCellEndAlign = styled(TableCell)`
  text-align: end;
`

const TableTitle = styled(TableCell)``

const GridDivider = styled(Divider)`
  grid-column: 1 / -1;
`

function getBillingPeriod(renewalDate: string) {
  const now = new Date().toISOString()
  const begin = formatDate({ date: now, excludeTime: true })
  const end = formatDate({ date: renewalDate, excludeTime: true })
  const billingPeriod = `${begin} - ${end}`
  return billingPeriod
}
