import { createContext, useContext, useState } from "react"

import { AxiosError } from "axios"

import { TCallAssistActivateError } from "src/components/CallAssistActivate/callAssistTypes"
import { useAppData } from "src/context/useAppData"
import {
  useFetchCallAssistActivationEstimate,
  useFetchCallAssistSubscription,
} from "src/data/callAssist/queries/callAssistQueries"
import { TCallAssistEstimateResponse } from "src/data/callAssist/types/callAssistTypes"
import { useFetchHasResponseServiceActive } from "src/data/homes/queries/responseServiceQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getAccessLogic } from "src/data/user/logic/accessLogic"

const CallAssistActivateContext = createContext({
  initialLoading: false,
  get initialLoadingError(): AxiosError | null {
    return null
  },
  get providerError(): TCallAssistActivateError {
    return undefined
  },
  get callAssistEstimate(): TCallAssistEstimateResponse | undefined {
    return undefined
  },
  callAssistAddonActivated: false,

  // For testing:
  get callAssistErrorOverride(): TCallAssistActivateError {
    return undefined
  },
  setCallAssistErrorOverride(_value: TCallAssistActivateError): void {},
})

export function CallAssistActivateProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { user_id: userId } = useGetUser()
  const { org } = useOrganization()
  const { currentSubscription } = useAppData()

  const [callAssistErrorOverride, setCallAssistErrorOverride] =
    useState<TCallAssistActivateError>()

  // Fetch and set initial required data:
  const fetchCallAssistSubscription = useFetchCallAssistSubscription({ userId })
  const fetchHasResponseServiceActive = useFetchHasResponseServiceActive()
  const fetchCallAssistActivationEstimate =
    useFetchCallAssistActivationEstimate({
      options: { enabled: !!currentSubscription },
    })

  const initialLoading =
    fetchCallAssistSubscription.isInitialLoading ||
    fetchHasResponseServiceActive.isInitialLoading ||
    fetchCallAssistActivationEstimate.isInitialLoading

  const initialLoadingError =
    fetchCallAssistSubscription.error ||
    fetchCallAssistActivationEstimate.error ||
    fetchHasResponseServiceActive.error

  const callAssistStatus = fetchCallAssistSubscription.data?.status
  const noPlan = !currentSubscription
  const legacyPlan = !!currentSubscription?.legacy_plan
  const isOwner = getAccessLogic({ role: org.user_role }).hasOwnerAccess
  const callAssistEstimate = fetchCallAssistActivationEstimate.data

  function getError(): TCallAssistActivateError {
    if (callAssistErrorOverride) {
      return callAssistErrorOverride
    }

    // specific errors
    if (!isOwner) {
      return "insufficient permission"
    }
    if (noPlan || legacyPlan) {
      return "wrong plan"
    }
    if (
      fetchCallAssistActivationEstimate.error?.response?.data?.error_key ===
      "already_active"
    ) {
      return "already_active"
    }
    if (!!fetchHasResponseServiceActive.data) {
      return "GA active"
    }
    if (
      fetchCallAssistActivationEstimate.error?.response?.data?.error_key ===
      "bad_state"
    ) {
      return "bad_state"
    }

    // catch all error
    if (initialLoadingError) {
      return "unknown error"
    }

    return // no error
  }
  const providerError = getError()

  const callAssistAddonActivated = Boolean(
    !callAssistErrorOverride && callAssistStatus === "active"
  )

  return (
    <CallAssistActivateContext.Provider
      value={{
        callAssistErrorOverride,
        setCallAssistErrorOverride,
        initialLoading,
        callAssistAddonActivated,
        callAssistEstimate,
        initialLoadingError,
        providerError,
      }}
    >
      {children}
    </CallAssistActivateContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export function useCallAssistActivateProvider() {
  return useContext(CallAssistActivateContext)
}
