import styled from "styled-components"

import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function CallAssistCard({
  children,
  heading,
  footer,
  error,
}: {
  children: React.ReactNode
  heading: React.ReactNode
  footer: React.ReactNode
  error?: React.ReactNode
}) {
  return (
    <CardContents>
      <MText variant="heading2">{heading}</MText>

      {children}

      {error && <div>{error}</div>}

      {footer}
    </CardContents>
  )
}

const CardContents = styled.div`
  display: grid;
  gap: ${spacing.XL};
  align-content: start;
`

// eslint-disable-next-line react-refresh/only-export-components
export const CallAssistCardContents = styled.div`
  display: grid;
  gap: ${spacing.M};
`

// eslint-disable-next-line react-refresh/only-export-components
export const CallAssistCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${spacing.XL};

  & > :only-child {
    margin-left: auto;
  }
`
