import styled from "styled-components"

import { TCallAssistActivateError } from "src/components/CallAssistActivate/callAssistTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { systemEmergencyForeground } from "src/ui/colors"
import ImportantSvg from "src/ui/icons/important-filled.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

type Props = {
  errorType: TCallAssistActivateError
}

export function CallAssistActivateError({ errorType }: Props) {
  const { t, langKeys } = useTranslate()

  switch (errorType) {
    case undefined:
      return null

    case "GA active":
      return <GuardAssistIsActive />

    case "payment_error":
      return <PaymentFailed />

    case "insufficient permission":
      return (
        <MBanner type="warning" fullWidth style={{ flexBasis: "100%" }}>
          {t(
            langKeys.activate_call_assist_only_organization_owner_can_activate_warning
          )}
        </MBanner>
      )

    case "no_homes":
      return (
        <MBanner type="error" fullWidth style={{ flexBasis: "100%" }}>
          {t(langKeys.activate_call_assist_no_homes_warning)}
        </MBanner>
      )

    case "already_active":
      return (
        <MBanner type="info" fullWidth style={{ flexBasis: "100%" }}>
          {t(langKeys.activate_call_assist_error_already_active)}
        </MBanner>
      )

    case "wrong plan":
    case "unknown error":
    case "generic_error":
    default:
      return (
        <MBanner type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      )
  }
}

function GuardAssistIsActive() {
  const { t, langKeys } = useTranslate()
  return (
    <MBanner type="warning" fullWidth>
      {t(
        langKeys.activate_call_assist_guard_and_call_assist_simultaneously_warning
      )}
    </MBanner>
  )
}

function PaymentFailed() {
  const { t, langKeys } = useTranslate()
  return (
    <MBanner type="error" fullWidth>
      <PaymentFailedContents>
        <ImportantSvg fill={systemEmergencyForeground} width={"24px"} />

        <div>
          {t(langKeys.payment_error_body)}{" "}
          <InternalLink to={Routes.AccountBilling.location()}>
            {t(langKeys.review_payment_method)}.
          </InternalLink>
        </div>
      </PaymentFailedContents>
    </MBanner>
  )
}

const PaymentFailedContents = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS};
`
