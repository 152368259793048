import {
  TCallAssistEstimateResponseErrors,
  TCallAssistSubscriptionPatchRequestError,
} from "src/data/callAssist/types/callAssistTypes"

export type TCallAssistActivateError =
  | TCallAssistSubscriptionPatchRequestError["error_key"]
  | TCallAssistEstimateResponseErrors["error_key"]
  | "wrong plan"
  | "GA active"
  | "insufficient permission"
  | "unknown error"
  | undefined

export function isRecoverableError(e: TCallAssistActivateError): boolean {
  return e === "payment_error" || e === "generic_error"
}
