import {
  CallAssistCard,
  CallAssistCardContents,
  CallAssistCardFooter,
} from "src/components/CallAssistActivate/CallAssistActivateCommon"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"

export function CallAssistActivateSuccess() {
  const { t, langKeys } = useTranslate()

  return (
    <CallAssistCard
      heading={t(langKeys.activate_call_assist_success_title)}
      children={
        <CallAssistCardContents>
          {t(langKeys.activate_call_assist_success_body)}
        </CallAssistCardContents>
      }
      footer={
        <CallAssistCardFooter>
          <MButtonLegacy size="large" appHref={Routes.Settings.location()}>
            {t(langKeys.go_to_settings)}
          </MButtonLegacy>
        </CallAssistCardFooter>
      }
    />
  )
}
